const messages = {
    selectAUser: 'Select a user',
    selectAManager: 'Select a manager',
    assignManager: 'Assign manager',
    assignProvider: 'Assign provider',
    manager: 'Manager',
    managers: 'Managers',
    userPhone: 'User phone',
    userEmail: 'User email',
    lastMessage: 'Last Message',
    lastMessageTime: 'Last Message Time',
    departmentCategory: 'Department Category',
    phoneNumber: 'Phone number',
    media: 'Media',
    twilioSid: 'Twilio Sid',
    sales: 'Sales',
    marketing: 'Marketing',
    coordination: 'Coordination',
    internal: 'Internal',
    recording: 'Recording',
    messageHistory: 'Message history',
    selectACategory: 'Select a category',
    addNumber: 'Add number',
    editNumber: 'Edit number',
};

export default messages;
