import { RouterView } from 'vue-router';
const ManageNumbers = () => import('@/pages/messaging/admin/ManageNumbers.vue');
const StrandedMessages = () => import('@/pages/messaging/admin/StrandedMessages.vue');
const BrowseChannels = () => import('@/pages/messaging/admin/BrowseChannels.vue');
const SearchAllMessages = () => import('@/pages/messaging/admin/SearchAllMessages.vue');
const AssignManagers = () => import('@/pages/messaging/admin/AssignManagers.vue');
const AssignCoordinators = () => import('@/pages/messaging/admin/AssignCoordinators.vue');
const ReconcileMessages = () => import('@/pages/messaging/admin/ReconcileMessages.vue');

const messagingRoutes = [
    {
        path: 'messaging',
        name: 'messaging',
        redirect: () => {
            return { path: '/messaging/manage' };
        },
        component: RouterView,
        meta: {
            title: 'Messaging',
            public: false,
            icon: 'ChatIcon',
        },
        children: [
            {
                path: 'manage',
                name: 'ManageNumbers',
                component: ManageNumbers,
                meta: {
                    menu: true,
                    title: 'Manage Numbers',
                    permissionsAllOf: ['Permissions.Messaging.Administration.SuperAdmin'],
                },
            },
            {
                path: 'assign-managers',
                name: 'AssignManagers',
                component: AssignManagers,
                meta: {
                    menu: true,
                    title: 'Assign Managers',
                    permissionsAllOf: ['Permissions.Messaging.Administration.SuperAdmin'],
                },
                props: {
                    allowDelete: true,
                    withFilters: true,
                },
            },
            {
                path: 'assign-coordinators',
                name: 'AssignCoordinators',
                component: AssignCoordinators,
                meta: {
                    menu: true,
                    title: 'Assign Coordinators',
                    permissionsAllOf: ['Permissions.Messaging.Administration.SuperAdmin'],
                },
            },
            {
                path: 'stranded',
                name: 'StrandedMessages',
                component: StrandedMessages,
                meta: {
                    menu: true,
                    title: 'Stranded Messages',
                    permissionsAllOf: ['Permissions.Messaging.StrandedMessages.Search'],
                },
            },
            {
                path: 'browse-channels',
                name: 'BrowseChannels',
                component: BrowseChannels,
                meta: {
                    menu: true,
                    title: 'Browse Channels',
                    permissionsAnyOf: [
                        'Permissions.Messaging.Administration.SuperAdmin',
                        'Permissions.Messaging.Administration.Management',
                    ],
                },
            },
            {
                path: 'search-messages',
                name: 'SearchAllMessages',
                component: SearchAllMessages,
                meta: {
                    menu: true,
                    title: 'Search Messages',
                    permissionsAllOf: ['Permissions.Messaging.Administration.SuperAdmin'],
                },
            },
            {
                path: 'reconcile-messages',
                name: 'ReconcileMessages',
                component: ReconcileMessages,
                meta: {
                    menu: true,
                    title: 'Reconcile Messages',
                    permissionsAllOf: ['Permissions.Messaging.Administration.SuperAdmin'],
                },
            },
        ],
    },
];

export default messagingRoutes;
