//import configRoutes from './configuration.routes';
import humanResourcesRoutes from './humanResources.routes';
import identityRoutes from './identity.routes';
import profileRoutes from './profile.routes';
import settingsRoutes from './settings.routes';
import publicityRoutes from './publicity.routes';
import salesRoutes from './sales.routes';
import inventoryRoutes from './inventory.routes';
import frontDeskRoutes from './frontdesk.routes';
import accountingRoutes from './accounting.routes';
import coordinationRoutes from './coordination.routes';
import medicalServicesRoutes from './medical.routes';
import customerServiceQARoutes from './customerServiceQA';
import planningTasks from './planningTasks.routes';
import cosmetology from './cosmetology.routes';
import reportsRoutes from '@/routes/reports.routes';
import adminRoutes from '@/routes/administration.routes';
import messagingRoutes from '@/routes/messaging.routes';

const routes = [
    // ...configRoutes,
    ...identityRoutes,
    ...humanResourcesRoutes,
    ...profileRoutes,
    ...settingsRoutes,
    ...publicityRoutes,
    ...salesRoutes,
    ...inventoryRoutes,
    ...frontDeskRoutes,
    ...accountingRoutes,
    ...coordinationRoutes,
    ...medicalServicesRoutes,
    ...customerServiceQARoutes,
    ...planningTasks,
    ...cosmetology,
    ...reportsRoutes,
    ...adminRoutes,
    ...messagingRoutes,
];

export default routes;
