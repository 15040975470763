const messages = {
    addNewPaymentType: 'Add New Payment Type',
    editNewPaymentType: 'Edit New Payment Type',
    addPayment: 'Add Payment',
    addPaymentMethods: 'Add Payment Methods',
    addPaymentsTypes: 'Add Payments Types',
    paymentsTypes: 'Payments Types',
    invoiceNumber: 'Invoice Number',
    valueToPaid: 'Value to Paid',
    paymentTypeId: 'Payment Type',
    paymentType: 'Payment Type',
    paymentTypeAddedSuccessfully: 'Payment Type added successfully',
    paymentTypeUpdatedSuccessfully: 'Payment Type updated successfully',
    paymentsByProvider: 'Monthly payments to providers',
    moneyCollectionPerDay: 'Collection Statistics',
    graphMoneyCollectionPerDay: 'Collection Operations per Day',
    numberMoneyCollectionPerDay: 'Amount of Money Collections per Day',
    companyLegalDataEditedSuccessfully: 'Company legal data edited successfully',
    companyLegalDataNote: 'This information is necessary for some payment documents',
    companyLegalDataAlert: 'This information is necessary for some payment documents',
    companyLegalData: 'Company Legal Data',
    confirmDeletePaymentMethod: 'Confirm Deletion of Payment Method',
    confirmDeletePayment: 'Confirm Deletion of Payment',
    paymentDeletedSuccessfully: 'Payment deleted successfully',
    paymentAddedSuccessfully: 'Payment added successfully',
    paymentUpdatedSuccessfully: 'Payment updated successfully',
    unauthorizedAction: "You don't have authorization to perform this operation",
    unauthorizedStatistics: "You don't have authorization to view this statistics",
    unauthorizedPaymentsStatistics: "You don't have authorization to view payments statistics",
    unauthorizedAddPayments: "You don't have authorization to add payments",
    unauthorizedEditPayments: "You don't have authorization to edit payments",
    unauthorizedPaymentsList: "You don't have authorization to view payments list",
    unauthorizedPaymentsTypesList: "You don't have authorization to view payments types list",
    unauthorizedEditCompanyLegalData: "You don't have authorization to edit company legal data",
    unauthorizedBalanceAdjustmentsList:
        "You don't have authorization to view balance adjustments list",
    unauthorizedBalanceAdjustmentsHistory:
        "You don't have authorization to view balance adjustments history",
    unauthorizedCreateBalanceAdjustments:
        "You don't have authorization to create balance adjustment",
    Done: 'Done',
    paymentState: 'Payment State',
    addExternalProvider: 'Add External Provider',
    editExternalProvider: 'Edit External Provider',
    editPayment: 'Edit Payment',
    checkNumber: 'Check Number',
    printPayment: 'Print Payment',
    paymentDelete: 'Payment Delete',
    youWantToRemovePayment: 'You Want to Remove Payment',
    youWantToRemoveExternalProvider: 'You Want to External Provider',
    unauthorizedCreateLockPatient: "You don't have authorization to lock patient",
    payPeriodStartDate: 'Pay Period Start Date',
    payPeriodEndDate: 'Pay Period End Date',
    quoteLogs: 'Quote Logs',
    modifiedBy: 'Modified By',
    QuoteTransfers: 'Quote Transfers',
    TreatmentTransfers: 'Treatment Transfers',
    allTreatmentTransfers: 'All Treatment Transfers',
    validateInfoInvoice: 'Fill out the information to view the invoice',
};

export default messages;
