<script setup lang="ts">


import { ref, onUnmounted, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useDialerStore } from '@/store/dialer.store';
import { useIdentityStore } from '@/store/identity.store';

const { t } = useI18n({});
const dialerStore = useDialerStore();
const identityStore = useIdentityStore();

const {
    message,
    dialNumber,
    isTwilioDeviceReady,
    isTwilioDeviceConnected,
    isActiveCall,
    hasIncomingConnection,
    ready,
} = storeToRefs(dialerStore);
const { onReadyClicked, acceptCallWrapper, endCallWrapper, rejectCallWrapper } = dialerStore;

const showDialer = ref(true);
const dialKeys = '123456789*0#';
const messagingPermisison = computed(() =>
    identityStore.authorized(
        ['Permissions.Messaging.Messages.Search'] as string[] | undefined,
        undefined
    )
);

const pressKey = (key) => {
    dialNumber.value += key;
};

const removeKey = () => {
    dialNumber.value = dialNumber.value.slice(0, -1);
};

const toggleShowDialer = () => {
    showDialer.value = !showDialer.value;
};

const startCallWrapper = () => {
    dialerStore.startCallWrapper(dialNumber.value);
};

onUnmounted(() => {
    dialerStore.destroy();
});

</script>
<template>
    <div
        v-if="messagingPermisison"
        :class="[
            'dialer',
            hasIncomingConnection || isTwilioDeviceConnected ? 'dialer--show-top' : '',
            hasIncomingConnection ? 'dialer--incoming-call' : '',
            showDialer ? 'dialer--show' : 'dialer--hide',
        ]"
    >
        <button
            v-if="!ready"
            id="ready-button"
            class="bg-orange-500 hover:bg-orange-700 text-white font-bold py-2 px-4 rounded"
            @click="onReadyClicked"
        >
            I'm ready
        </button>
        <div v-if="ready">
            <button
                :class="[
                    'show-hide-button',
                    showDialer
                        ? 'bg-blue-500 hover:bg-blue-700'
                        : 'bg-green-500 hover:bg-green-700',
                    'text-white font-bold py-2 px-4 rounded',
                ]"
                @click="toggleShowDialer"
            >
                {{ showDialer ? t('hide') : t('show') }} {{ t('phone') }}
                <span class="oi oi-phone"></span>
            </button>
            <p class="text-center text-gray-400">
                {{ message !== '' ? message : t('Standing by') }}
            </p>
            <div class="flex justify-center mb-4">
                <input
                    v-model="dialNumber"
                    :disabled="
                        !isTwilioDeviceReady || isTwilioDeviceConnected || hasIncomingConnection
                    "
                    type="text"
                    class="w-full px-4 py-2 rounded border border-gray-400 focus:outline-none focus:border-gray-600"
                    :placeholder="t('Phone Number')"
                />
                <button
                    :disabled="
                        !isTwilioDeviceReady ||
                        isTwilioDeviceConnected ||
                        hasIncomingConnection ||
                        dialNumber.length === 0
                    "
                    class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                    type="button"
                    @click="removeKey"
                >
                    <span class="oi oi-delete"></span>
                </button>
            </div>
            <div class="dial-pad grid grid-cols-3 gap-4 mb-4">
                <button
                    v-for="dialKey in dialKeys"
                    :key="dialKey"
                    :disabled="!isTwilioDeviceReady"
                    class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                    type="button"
                    @click="pressKey(dialKey)"
                >
                    {{ dialKey }}
                </button>
            </div>
            <div class="flex justify-center">
                <template v-if="hasIncomingConnection">
                    <button
                        type="button"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-1/2"
                        @click="rejectCallWrapper"
                    >
                        {{ t('Reject') }}
                    </button>
                    <button
                        type="button"
                        class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/2"
                        @click="acceptCallWrapper"
                    >
                        {{ t('Accept') }}
                    </button>
                </template>
                <template v-else>
                    <button
                        :disabled="!isTwilioDeviceReady || !isTwilioDeviceConnected"
                        type="button"
                        class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded w-1/2"
                        @click="endCallWrapper"
                    >
                        {{ t('Hang up') }}
                    </button>
                    <button
                        :disabled="
                            !isTwilioDeviceReady ||
                            isTwilioDeviceConnected ||
                            dialNumber.length === 0
                        "
                        type="button"
                        class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded w-1/2"
                        @click="startCallWrapper"
                    >
                        {{ t('Call') }}
                    </button>
                </template>
            </div>
        </div>
    </div>
</template>
<style scoped>
.dialer {
    background-color: #fff;
    position: fixed;
    bottom: -540px;
    right: 30px;
    padding: 20px 30px;
    width: 295px;
    border-top-left-radius: 5px;
    transition: 1s bottom;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.dialer--show-top {
    bottom: -450px;
}

.dialer--incoming-call {
    animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) infinite both;
}

.dialer--show {
    bottom: 0;
}

.show-hide-button {
    position: absolute;
    top: -38px;
    right: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}

.dial-pad button {
    display: block;
    float: left;
    border-radius: 37.5px;
    width: 75px;
    height: 75px;
    margin-bottom: 5px;
    margin-right: 5px;
    font-size: 24px;
}

.dial-pad button:nth-child(3n) {
    margin-right: 0;
}

@keyframes shake {
    5%,
    45% {
        transform: translate3d(-1px, 0, 0);
    }

    10%,
    40% {
        transform: translate3d(1px, 0, 0);
    }

    15%,
    25%,
    35% {
        transform: translate3d(-2px, 0, 0);
    }

    20%,
    30% {
        transform: translate3d(2px, 0, 0);
    }
}
</style>
