<script setup lang="ts">


import Notification from './components/common/NotificationComponent.vue';
import { getAccessibleColor, getRGBColor } from './colors.utils';
import ConfirmDialog from './components/common/ConfirmDialog.vue';
import DialerDialog from '@/pages/messaging/DialerDialog.vue';

const primaryColor = getRGBColor('#6231af', 'primary');
const a11yColor = getRGBColor(getAccessibleColor('#6231af'), 'a11y');

</script>

<template>
    <vue3-progress-bar> </vue3-progress-bar>
    <router-view></router-view>
    <Notification />
    <ConfirmDialog />
    <DialerDialog />
</template>

<style>
:root {
    --primary: #742a2a;
    --secondary: #e53e3e;
}
</style>
