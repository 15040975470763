const messages = {
    to: 'A',
    notReceiptFound: 'No Excite el Recibo',
    noReceipt: 'Número de Recibo',
    receipt: 'Recibo',
    referenceCode: 'Código de Referencia',
    transactionFee: 'Transacción de la Tarifa',
    patientNumber: 'Número del Paciente',
    invoice: 'Factura',
    invoiceDate: 'Fecha de Factura',
    accountNumber: 'Número de Cuenta',
    invoiceDataNotAvailable: 'Los datos de la factura aún no están disponibles.',
    invoiceDataIsLoading: 'Los datos de la factura están cargando.',
    validateInfoInvoice: 'Complete la information para ver la factura.',
};

export default messages;
